<template>
  <Page color="info" title="Mein Unterricht">
    <template slot="extension">
      <v-tabs
        background-color="info darken-1"
        :align-with-title="!$vuetify.breakpoint.xs"
        rounded
      >
        <v-tab
          v-if="registerVisible"
          :to="{
            name: 'MyRegister',
            props: { person: person, view: 'list' },
          }"
          >Unterricht</v-tab
        >
        <v-tab v-if="examsVisible" :to="{ name: 'MyExams' }">Probenplan</v-tab>
      </v-tabs>
    </template>
    <router-view :person="person"></router-view>
  </Page>
</template>

<script>
export default {
  name: "PersonMe",
  components: {},
  computed: {
    id() {
      return this.$_profilePerson.id;
    },
    examsVisible() {
      if (this.$_hasRole("student")) {
        return this.$_isPerson(this.person);
      } else {
        return true;
      }
    },
    registerVisible() {
      if (this.$_hasRole("student")) {
        return (
          (this.person && this.person.type.code === "teacher") ||
          this.$_isPerson(this.person)
        );
      } else {
        return true;
      }
    },
  },
  data() {
    return {
      loading: false,
      person: null,
    };
  },
  watch: {
    id() {
      if (this.id > 0) {
        this.fetchData();
      }
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      this.person = await this.apiGet({
        resource: "person/person",
        id: this.id,
      });
      this.loading = false;
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
